<template>
	<div v-if="settings" class="settings">
		<TransitionGroup name="fade" appear>
			<h2 :key="1">{{ translate('settings') }}</h2>
			<p :key="2" class="subtitle">{{ translate('settings.subtitle') }}</p>
		</TransitionGroup>

		<Transition name="fade-slide-up" appear>
			<div class="profile">
				<div class="avatar-container">
					<label
						v-if="!uploading"
						v-tippy
						for="file"
						class="edit"
						:content="translate('changeAvatar')">
						<Icon name="edit-outline" class="white" width="20" />
					</label>

					<radial-progress-bar
						v-if="uploadProgress"
						:diameter="176"
						:completed-steps="uploadProgress"
						:total-steps="100"
						:start-color="'#10ac84'"
						:stop-color="'#10ac84'"
						:inner-stroke-color="'white'"
						:stroke-width="6"
						:stroke-line-cap="'square'"></radial-progress-bar>

					<Avatar :user="settings" :size="150" :hover="!uploading" :loading="uploading">
						<div v-if="!uploading" class="upload-input">
							<label for="file" class="secondary icon-left small"
								><Icon name="cloud-upload-outline" width="20" />{{ translate('upload') }}</label
							>
							<input
								id="file"
								type="file"
								accept="image/gif, image/jpeg, image/png"
								@change="uploadAvatar($event)" />
						</div>

						<div v-if="uploading" class="uploading">
							<Loader />
						</div>
					</Avatar>

					<div class="upload-success" :class="{ show: uploadCompleted }">
						<Icon name="checkmark-circle-2" class="white" width="24" />
					</div>
				</div>

				<div class="fields">
					<div class="row two">
						<div class="form-input column" :class="{ error: error.name }" :data-error="error.name">
							<label for="name">{{ translate('name') }}</label>
							<Icon name="person" width="18" />
							<input
								id="name"
								v-model="settings.display_name"
								type="text"
								:class="[`state-${state.name}`]"
								placeholder="John Doe"
								@input="updateDisplayName($event)" />
							<InputState :state="state.name" :show="!!state.name" />
						</div>

						<div
							class="form-input column"
							:class="{ error: error.email }"
							:data-error="error.email">
							<label for="email">{{ translate('emailAddress') }}</label>
							<Icon name="email" width="18" />
							<input
								id="email"
								v-model="settings.email"
								type="email"
								:class="[`state-${state.email}`]"
								placeholder="john.doe@email.com"
								@input="updateEmail($event)" />
							<InputState :state="state.email" :show="!!state.email" />
						</div>
					</div>

					<div class="row three">
						<div
							class="form-input column"
							:class="{ error: error.username }"
							:data-error="error.username">
							<label for="username">{{ translate('username') }}</label>
							<Icon name="at" width="18" />
							<input
								id="username"
								v-model="settings.username"
								type="username"
								:class="[`state-${state.username}`]"
								placeholder="johndoe"
								@input="updateUsername($event)" />
							<InputState :state="state.username" :show="!!state.username" />
						</div>

						<div class="form-input column" :class="{ error: error.password }">
							<label for="password">{{ translate('settings.changePassword') }}</label>
							<Icon name="lock" width="18" />
							<input
								id="password"
								v-model="password[0]"
								type="password"
								:class="[`state-${state.password}`]"
								placeholder="••••••••" />
							<InputState :state="state.password" :show="!!state.password" />
						</div>

						<div
							class="form-input column"
							:class="{ error: error.password }"
							:data-error="error.password">
							<label for="confirm-password">{{ translate('settings.confirmPassword') }}</label>
							<Icon name="lock" width="18" />
							<input
								id="confirm-password"
								v-model="password[1]"
								type="password"
								:class="[`state-${state.password}`]"
								placeholder="••••••••"
								@input="changePassword()" />
							<InputState :state="state.password" :show="!!state.password" />
						</div>
					</div>
				</div>
			</div>
		</Transition>

		<!-- TODO: Add logic to handle account deletion
    <div class="dangerzone">
      <h4>{{ translate('settings.deleteAccount') }}</h4>
      <p>{{ translate('settings.deleteAccount.subtitle') }}</p>
      <div class="checkbox">
        <input type="checkbox" id="confirmDeletion" v-model="confirmDeletion">
        <label for="confirmDeletion">
          <span class="box">
            <Icon name="checkmark" width="14" />
          </span>
          <span class="text">{{ translate('settings.deleteAccount.confirm') }} @{{ settings.username }}</span>
        </label>
        <button class="red" @click="deleteAccount()" :class="{ disabled: !confirmDeletion }" :disabled="!confirmDeletion">{{ translate('delete') }}</button>
      </div>
    </div>-->
	</div>
</template>

<script>
import { auth, db } from '@/lib/supabase'
import { mapGetters } from 'vuex'
import { debounce } from 'debounce'
import { upload } from '@/utils/upload'
import { handleError } from '@/utils/utils'

import Avatar from '@/components/Avatar'
import InputState from '@/components/InputState'
import Loader from '@/components/Loader'
import RadialProgressBar from 'vue-radial-progress'
import Icon from '../components/Icon.vue'

export default {
	components: {
		Avatar,
		InputState,
		Loader,
		RadialProgressBar,
		Icon,
	},

	data() {
		return {
			password: ['', ''],

			settings: null,

			state: {
				name: '',
				username: '',
				email: '',
				password: '',
			},

			error: {
				name: null,
				username: null,
				email: null,
				password: null,
			},

			confirmDeletion: false,

			uploading: false,

			uploadProgress: null,

			uploadCompleted: false,
		}
	},

	computed: {
		...mapGetters(['user']),
	},

	async beforeMount() {
		this.settings = await this.getSettings()
	},

	methods: {
		async getSettings() {
			const { data: user, error } = await db
				.from('users')
				.select('*')
				.eq('id', auth.user().id)
				.limit(1)
				.maybeSingle()

			if (error) handleError(error)
			else return { ...auth.user(), ...user }
		},

		updateDisplayName(e) {
			this.error.name = null
			this.state.name = 'loading'

			let value = e.target.value

			if (value) {
				this.debounce(async () => {
					const { data: user, error } = await db
						.from('users')
						.update({ display_name: value })
						.eq('id', auth.user().id)

					if (error) {
						handleError(error)
						this.settings.displayName = this.user.displayName
						this.state.name = 'error'
						this.handleFormError({ type: 'name', message: error.message, code: error.code })
					} else {
						await this.$store.dispatch('setUser', user)
						this.state.name = 'success'
						setTimeout(() => {
							this.state.name = ''
						}, 1500)
					}
				})
			} else {
				this.handleFormError({ type: 'name', message: this.translate('settings.error.emptyName') })
				this.state.name = 'error'
			}
		},

		updateEmail(e) {
			this.error.email = null
			this.state.email = 'loading'

			let value = e.target.value

			if (value) {
				this.debounce(async () => {
					const { error } = await auth.update({ email: value })

					if (error) {
						this.settings.email = this.user.email
						this.state.email = 'error'
						this.handleFormError({ type: 'email', message: error.message, code: error.code })
					} else {
						this.state.email = 'success'
						setTimeout(() => {
							this.state.email = ''
						}, 1500)
					}
				})
			} else {
				this.handleFormError({
					type: 'email',
					message: this.translate('settings.error.emptyEmail'),
				})
				this.state.email = 'error'
			}
		},

		async updateUsername(e) {
			this.error.username = null
			this.state.username = 'loading'

			let value = e.target.value

			if (value) {
				await this.debounce(async () => {
					const { error } = await db
						.from('users')
						.update({ username: value })
						.match({ id: auth.user().id })

					if (error) {
						this.state.username = 'error'

						if (error.code === '23505') {
							this.handleFormError({
								type: 'username',
								message: this.translate('settings.error.usernameOccupied'),
							})
						} else {
							this.handleFormError({ type: 'username', message: error.message })
						}
					} else {
						this.state.username = 'success'

						setTimeout(() => {
							this.state.username = ''
						}, 1500)
					}
				})
			} else {
				this.handleFormError({
					type: 'username',
					message: this.translate('settings.error.emptyUsername'),
				})
				this.state.username = 'error'
			}
		},

		changePassword() {
			if (!this.password[1]) return

			this.error.password = null
			this.state.password = 'loading'

			this.debounce(async () => {
				if (this.password[1] !== this.password[0]) {
					this.state.password = 'error'
					this.handleFormError({
						type: 'password',
						message: this.translate('settings.error.passwordsDontMatch'),
					})
					return
				}

				const { error } = await auth.update({ password: this.password[0] })

				if (error) {
					this.password = ['', '']
					this.state.password = 'error'
					this.handleFormError({ type: 'password', message: error.message, code: error.code })
				} else {
					this.state.password = 'success'
					setTimeout(() => {
						this.state.password = ''
					}, 1500)
				}
			})
		},

		deleteAccount() {
			if (!this.confirmDeletion) return

			// TODO: Delete user
			//db.doc(`users/${ auth.currentUser.uid }`)
			//  .delete()
			//  .then(() => {
			//    auth.currentUser.delete().then(() => {
			//      this.$store.commit('updateUser', null);
			//      this.$router.replace({ name: 'Login' });
			//    });
			//  }).catch(error => {
			//    console.error(error);
			//  });
		},

		// TODO: See if its possible to display upload progress
		async uploadAvatar(e) {
			this.uploading = true
			const file = e.target.files[0]

			const { publicURL, error } = await upload(file, 'avatars')

			if (error) handleError(error)
			else {
				const { error: e } = await db
					.from('users')
					.update({ avatar_url: publicURL })
					.eq('id', auth.user().id)
				if (e) handleError(e)
				this.settings.avatar_url = publicURL
				this.uploading = false
				this.uploadCompleted = true

				setTimeout(() => {
					this.uploadCompleted = false
				}, 1500)
			}
		},

		handleFormError(error) {
			let message = error.code ? this.translate(error.code) : null

			if (message === error.code) {
				message = null
			}

			this.error[error.type] = message || error.message
		},

		debounce: debounce(function (fn) {
			fn()
		}, 2500),
	},
}
</script>

<style lang="scss" scoped>
.settings {
	text-align: center;

	.profile {
		align-items: flex-start;
		//border-bottom: 2px dashed #d9d9d9;
		display: flex;
		margin: 50px 0 35px;
		padding-bottom: 35px;
		text-align: left;

		@include mobile {
			align-items: center;
			flex-direction: column;
		}

		.avatar-container {
			margin-right: 50px;
			position: relative;

			@include mobile {
				margin-right: 0;
				margin-bottom: 25px;
			}

			::v-deep .avatar {
				margin: 10px;
			}

			.upload-input {
				label {
					@extend button;
				}

				input {
					left: -9999px;
					position: fixed;
				}
			}

			::v-deep .radial-progress-container {
				left: -3px;
				position: absolute;
				top: -3px;
			}

			.edit {
				$size: 35px;

				align-items: center;
				background: $primary-color;
				border-radius: $size;
				cursor: pointer;
				display: flex;
				height: $size;
				justify-content: center;
				left: auto;
				position: absolute;
				right: 10px;
				top: 10px;
				width: $size;
				z-index: 10;

				&:hover {
					background: darken($primary-color, 7.5%);

					+ .avatar-placeholder:hover .hover {
						opacity: 1;
					}
				}
			}

			.upload-success {
				$size: 150px;

				align-items: center;
				background: rgba($green, 0.55);
				border-radius: $size;
				display: flex;
				height: $size;
				left: 10px;
				justify-content: center;
				opacity: 0;
				position: absolute;
				pointer-events: none;
				top: 10px;
				transition: $transition;
				width: $size;
				z-index: 9;

				&.show {
					opacity: 1;
				}
			}
		}

		.fields {
			flex-grow: 1;

			@include mobile {
				width: 100%;
			}

			.row {
				margin-bottom: 40px;

				@include mobile {
					margin-bottom: 0;
				}
			}
		}
	}

	.dangerzone {
		background: rgba($red, 0.05);
		border: 2px solid rgba($red, 0.3);
		border-radius: $border-radius;
		padding: 20px;
		text-align: left;

		h4 {
			color: $red;
		}

		p {
			font-size: 14px;
			margin: 6px 0 15px;
		}

		button {
			margin-top: 20px;
		}
	}
}
</style>
